import { FetchEbookURLSettingsUsecase } from "@Features/homepage/domain/FetchEbookurlSettings.usecase";
import {
  MenuItem,
  MenuItemList,
} from "@Libraries/components/headers/HeaderWithMenu.component";
import { UserSession } from "@Libraries/users/UserSession";
import { useEffect, useState } from "react";

export const CHECKOUT_ADDITIONAL_INFO = "CHECKOUT_ADDITIONAL_INFO";
export const CHECKOUT_AVAILABLE_PAYMENTS = "CHECKOUT_AVAILABLE_PAYMENTS";
export const AFFILIATE_DISCOUNT_AMOUNT = "AFFILIATE_DISCOUNT_AMOUNT";

export const GENERAL_SELECTED_PRODUCTS = "GENERAL_SELECTED_PRODUCTS";
export const GENERAL_TESTIMONI = "GENERAL_TESTIMONI";
export const HOMEPAGE_PORTFOLIO = "HOMEPAGE_PORTFOLIO";
export const HOMEPAGE_SHOWCASE = "HOMEPAGE_SHOWCASE";
export const MEMBERSHIP_HEADER_VIDEO = "MEMBERSHIP_HEADER_VIDEO";
export const MEMBERSHIP_INTRODUCTION_VIDEO = "MEMBERSHIP_INTRODUCTION_VIDEO";
export const MEMBERSHIP_SHOWCASE = "MEMBERSHIP_SHOWCASE";
export const MEMBERSHIP_CAROUSEL = "MEMBERSHIP_CAROUSEL";
export const MEMBERSHIP_FINDING_STOCKS = "MEMBERSHIP_FINDING_STOCKS";
export const MEMBERSHIP_FAQ = "MEMBERSHIP_FAQ";
export const MEMBERSHIP_FAQ_EMAIL = "MEMBERSHIP_FAQ_EMAIL";
export const STOCK_KEY_TEASER = "STOCK_KEY_TEASER";
export const PHILOSOPHY_TOP_HEADER = "PHILOSOPHY_TOP_HEADER";
export const PHILOSOPHY_CRITERIA = "PHILOSOPHY_CRITERIA";

export const MEMBER_OFFER_MEMBER_ONLY = "MEMBER_OFFER_MEMBER_ONLY";
export const MEMBER_OFFER_PORTOFOLIO_ONLY = "MEMBER_OFFER_PORTOFOLIO_ONLY";
export const MEMBER_OFFER_MEMBER_AND_PORTOFOLIO =
  "MEMBER_OFFER_MEMBER_AND_PORTOFOLIO";

export const MEMBER_UPGRADE_MEMBER = "MEMBER_UPGRADE_MEMBER";
export const MEMBER_UPGRADE_PORTOFOLIO = "MEMBER_UPGRADE_PORTOFOLIO";

export const USER_UPGRADE_SERVICES = "USER_UPGRADE_SERVICES";

export const AFFILIATE_TUTORIAL_VIDEO_LINK = "AFFILIATE_TUTORIAL_VIDEO_LINK";
export const AFFILIATE_TELEGRAM_GROUP = "AFFILIATE_TELEGRAM_GROUP";

export const DISCUSSION_GROUP = "DISCUSSION_GROUP";
export const STRATEGY_ROOM = "STRATEGY_ROOM";
export const PORTOFOLIO_GROUP = "PORTOFOLIO_GROUP";

export const STOCK_TREASURE_MAPS_TOOLS = "STOCK_TREASURE_MAPS_TOOLS";
export const STOCK_PORTOFOLIO_TOOLS = "STOCK_PORTOFOLIO_TOOLS";

export const EBOOK_URL = "EBOOK_URL";

export const getSiteMenus: () => MenuItemList[] = () => {
  let memberAreaMenu: MenuItem | null = null;

  let userPortofolio = false;

  let memberAreaPortofolio: MenuItem | null = null;

  let roleName = UserSession.getUserSession().activeRoles;

  for (let i = 0; i < roleName.length; i++) {
    if (
      roleName[i].role.title.split(" ")[0] === "Portofolio" ||
      roleName[i].role.title.split(" ")[2] === "Portofolio"
    ) {
      userPortofolio = true;
      break;
    }
  }

  if (
    UserSession.checkIsLoggedIn() &&
    UserSession.isUserCanAccessMemberArea()
  ) {
    memberAreaMenu = {
      text: "Member Area",
      destination: "/member-area",
    };
  } else {
    memberAreaMenu = {
      text: "Join Member",
      destination: "/membership",
    };
  }

  if (UserSession.checkIsLoggedIn() && userPortofolio) {
    memberAreaPortofolio = {
      text: "Member Area",
      destination: "/member-area/portfolio",
    };
  } else {
    memberAreaPortofolio = {
      text: "Join MIDAS Portofolio",
      destination: "/midas-portofolio",
    };
  }

  return [
    {
      text: "MIDAS Portofolio",
      destination: "/midas-portofolio",
      submenus: [memberAreaPortofolio],
    },
    {
      text: "Stock Membership",
      destination: "#",
      submenus: [memberAreaMenu],
    },
    {
      text: "FREE Ebook",
      destination: "/${ebookURL}",
      submenus: [],
    },
    {
      text: "Investment Philosophy",
      destination: "/investment-philosophy",
      submenus: [],
    },
    {
      text: "Cuan Kalkulator",
      destination: "/calculator",
      submenus: [],
    },
  ] as MenuItemList[];
};

export const GOOGLE_API_KEY = "AIzaSyC2G8zkURGI3MpUYaB-2iAQP-OF1eOjgX4";
export const GOOGLE_CLIENT_ID =
  "556339970781-9h3auje2h698i9p86h1gg27429hegf4k.apps.googleusercontent.com";
export const SS_CONFIG_ID = "1b_wuyqiIHYlH2lG3lesiNNFkaZL-w2GqOS6ay8crfiA";

export const KEY_AFFILIATE_ID = "affiliate_id";
export const KEY_CHECKOUT = "is_already_checkout";
export const AFFILIATE_DAY_DURATION = 14;
